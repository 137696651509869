<template xmlns:el-col="http://www.w3.org/1999/html">

  <div class="bbb" style="width: 100%">
    <el-col :span="21">
      <el-input
          id="input1"
          v-model="textarea"
          style="width: 100%"
          :rows="10"
          type="textarea"
          placeholder="请输入查询的问题"
      />
    </el-col>
  </div>

  <div class="bbb" style="width: 100%">
    <el-col :span="21">
    <el-input
        id="input1"
        v-model="answer"
        style="width: 100%"
        :rows="10"
        type="textarea"
        placeholder="展示答案的地方"
    />
    </el-col>
  </div>
  <el-button type="primary" @click="submitted" style="color:black">提交</el-button>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Main",

  data(){
    return{
      textarea:"",
      answer:"",
      ansJson: {
        "ans":""
      },
    }
  },
  methods:{
    submitted(){
        this.ansJson.ans=this.textarea;
        this.sendRequest();
    },
    sendRequest() {
      // 引入 axios
      const axios = require('axios');
//http://localhost:9999/lingHuApi/que
      // 发起 post 请求
      axios.post('http://101.42.37.137:9999/lingHuApi/que',this.ansJson)
          .then(response => {
            // 请求成功处理
            console.log(response.data);
            this.answer=response.data;
          })
          .catch(error => {
            // 请求失败处理
            console.error(error);
          });
    }
  }
}
</script>

<style scoped>
.container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* 或任何你希望的高度 */
}

.bbb /deep/ #input1 {
  min-height: 30px;
  margin: 0px;
  width: 1348px;
}
</style>