<template>
  <Main/>
  <br><br><br>
  <a href="https://beian.miit.gov.cn" id="beian" target="_blank">黔ICP备2024032272号-1</a><br>
  <a href="https://beian.mps.gov.cn/#/query/webSearch?code=52032202001885" rel="noreferrer" target="_blank">贵公网安备52032202001885</a>
</template>

<script>

import Main from "@/components/Main.vue";

export default {
  name: 'App',
  components: {
    Main,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
